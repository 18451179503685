<script>

import urlState from "@/router/urlState";
import moment from "moment/moment";
import {mapActions, mapState} from "vuex";
import {getProductionDays} from "@/store/utils";

const dayFormat = 'ddd';
const dateFormat = 'MMM D';

export default {
  name: "ProductionSelector",
  mixins: [urlState],
  async mounted() {
    await this.fetchUpcomingProductions();
  },
  methods: {
    ...mapActions(['fetchUpcomingProductions']),
    closeProductionPicker() {
      this.showDialog = false;
      const {dates, production} = this.selectedProduction || {}
      console.log('setting dates', dates);
      this.$emit('update:dates', dates);
      this.$emit('update:production', production);
      this.$emit('update', {dates, production});
      if (this.singleDay) {
        this.$emit('update:date', dates[0]);
      }
    },
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };
      return !notAllowed[moment(val).day()];
    },
    closeDatePicker(production) {
      this.showDialog = false;
      this.showDatePicker = false;
      this.dates = getProductionDays(this.datePickerDate);
      const dates = this.dates;
      this.$emit('update:dates', dates);
      this.$emit('update:production', production);
      this.$emit('update', {dates, production});
      if (this.singleDay) {
        this.$emit('update:date', dates[0]);
      }
    },
  },
  computed: {
    ...mapState(['productions']),
    upcomingProductions() {
      function formatProduction(p) {
        const productionMap = {
          shipMon: 1,
          localMon: 2,
          shipThu: 3,
          localThu: 4
        }
        const dates = p.dates.sort();
        const dateFrom = moment(dates[0]);
        const dateTo = moment(dates[dates.length - 1]);
        const startDay = dateFrom.format(dayFormat);
        const productionNumber = productionMap[`${p.production}${startDay}`] || '?';
        return {
          number: productionNumber,
          text: `${p.production}: ${startDay}-${dateTo.format(dayFormat)}`
            + ` (${dateFrom.format(dateFormat)}-${dateTo.format(dateFormat)})`
        };
      }

      const sortKey = a => `${a.dates[0]}-${a.number} `;
      let productions = (this.productions || []).slice();
      if (this.productionFilter) {
        productions = productions.filter(p => p.production === this.productionFilter);
      }
      return productions.map(p => ({...p, ...formatProduction(p)}))
        .sort((a, b) => sortKey(a).localeCompare(sortKey(b)));
    },
    dateFrom() {
      return [...(this.dates || [])].sort()[0];
    },
    dateTo() {
      return [...(this.dates || [])].sort().reverse()[0];
    },
    productionGroups() {
      return [...new Set(this.productions.map(p => p.production))];
    },
    datesFormatted() {
      const format = dateFormat;
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
  },
  data() {
    return {
      datePickerDate: null,
      showDialog: false,
      selectedProduction: null,
      showDatePicker: null
    }
  },
  props: {
    production: {required: false},
    dates: {type: Array},
    date: {type: String},
    singleDay: {type: Boolean, default: false},
    productionFilter: {type: String, default: null, required: false}
  }
}

</script>

<template>
  <div :class="`${production}-production`">
    <v-text-field
      :value="(production ? `${production.toUpperCase()} `:'')+`${datesFormatted}`"
      label="Select Date & Production Cycle"
      hide-details
      single-line
      readonly
      @click="showDialog=true"
      append-icon="mdi-calendar"
    />
    <v-dialog
      v-model="showDialog"
      ref="dialog"
      width="auto"
      persistent
      class="debugcss"
    >
      <v-card v-if="!showDatePicker">
        <v-card-title>
          <v-spacer/>
          Select Production
          <v-spacer/>
        </v-card-title>
        <v-card-actions>
          <div class="flex-column ">
            <div class="mb-8" v-for="(p,i) of upcomingProductions" v-bind:key="i">
              <v-btn
                width="100%"
                :class="`${p.production}-production`"
                outlined x-large @click="selectedProduction = p; closeProductionPicker()"

              >
                <v-chip style="border-width: thick" v-if="p.production==='ship'" outlined color="blue">
                  {{ p.number }}
                </v-chip>
                <v-chip style="border-width: thick" v-if="p.production==='local'" outlined>{{
                    p.number
                  }}
                </v-chip>
                <div class="text-left pl-4" style="width: 100%">
                  {{ p.text }}
                </div>
              </v-btn>
            </div>
          </div>
        </v-card-actions>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined x-small @click="showDatePicker=true">Show Other Dates</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
      <v-card v-if="showDatePicker">
        <v-card-text>
          <v-date-picker
            v-model="datePickerDate"
            no-title
            :allowed-dates="allowedDates"
            @close="closeDatePicker"
          ></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn v-for="p of productionGroups" v-bind:key="p"
                 @click="closeDatePicker(p)"
          >
            {{ p }}
          </v-btn>
          <v-spacer/>
          <!--          <v-btn @click="closeDatePicker">Close</v-btn>-->
        </v-card-actions>
        <v-card-actions class="mt-10">
          <v-spacer/>
          <v-btn outlined x-small @click="showDatePicker=false">Show Upcoming Productions</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>